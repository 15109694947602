import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import { Location } from "@reach/router"
import Img from "gatsby-image"


const Header = (props) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "avatar.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              src
            }
          }
        }
      }
    `}
    render={data => (
      <header className="logo">
        <div>
          <Link to="/">
            <img src={data.file.childImageSharp.fluid.src} className="logo-avatar" />
          </Link>
        </div>
      </header>
    )}
  />
)

export default Header
